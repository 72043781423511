/* eslint-disable semi */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import './Home.css';
import { SpotlightContainer3, HorizonalContainer3, SpotlightChunk, SpotlightChunkDesktop, SpotlightChunkMobile } from './HomePageComponents';
import { useNavigate } from "react-router-dom"
import { UserContext } from './App'
import InstaSVG from './instagram.svg'
import TheZuckSVG from './TheZuck.svg';
import BirdAppSVG from './BirdApp.svg';
import ClockAppSVG from './ClockApp.svg';

function HiringComponent() {
  const navigate = useNavigate()
  const [state, dispatch ] = React.useContext(UserContext)
  return (
    <SpotlightContainer3>
    <SpotlightChunkMobile>
      Hours: <br />
      Wednesday - Saturday <br />
      Noon - 6:00 PM
    </SpotlightChunkMobile>
    <SpotlightChunk>
      Email: meena@thebubblingteapot.com
    </SpotlightChunk>
    <SpotlightChunkDesktop>
      Phone: Coming Soon
    </SpotlightChunkDesktop>
    <SpotlightChunkMobile>
      Address: 2611 East Johnson Street <br />
      Madison, WI 53704
    </SpotlightChunkMobile>
    <SpotlightChunk>
        Contact us 
        on social media!
    </SpotlightChunk>
    <SpotlightChunkMobile>
      Phone: coming soon!
    </SpotlightChunkMobile>
    <HorizonalContainer3>
    <div>
    <a href="https://www.tiktok.com/@thebubblingteapot" target="_blank" rel='noreferrer'><img src={ClockAppSVG} style={{width: '4vh', height: '4vh', cursor: 'pointer'}}/></a>
    </div>
    <div>
    <a href="https://www.twitter.com/bubblingteaWI" target="_blank" rel='noreferrer'><img src={BirdAppSVG} style={{width: '4vh', height: '4vh', cursor: 'pointer'}}/></a>
    </div>
    <div>
    <a href="https://www.instagram.com/thebubblingteapot" target="_blank" rel='noreferrer'><img src={InstaSVG} style={{width: '4vh', height: '4vh', cursor: 'pointer'}}/></a>
    </div>
    <div>
    <a href="https://www.facebook.com/The-Bubbling-Teapot-109071808519120" target="_blank" rel='noreferrer'><img src={TheZuckSVG} style={{width: '4vh', height: '4vh', cursor: 'pointer'}}/></a>
    </div>
    </HorizonalContainer3>
    </SpotlightContainer3>
  );
}
export default HiringComponent;
