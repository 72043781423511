/* eslint-disable semi */
/* eslint-disable jsx-a11y/alt-text */
import React, {useEffect} from 'react';
import './Home.css';
import { SpotlightContainer, SpotlightChunkHiring, BigButton, HorizonalContainer, SpotlightChunk, SpotlightChunkSmall, SpotlightChunkMedium } from './HomePageComponents';
import { useNavigate } from "react-router-dom"
import { UserContext } from './App'
import axios from 'axios';
import CalendarPNG from './calenderp.jpg'
import { InputLabelSmall } from './HomePageComponents';

function CalendarComponent() {
  const navigate = useNavigate()
  const [state, dispatch ] = React.useContext(UserContext)
  return (
    <SpotlightContainer>
      <img src={CalendarPNG} style={{marginTop: '1vh', width: '100%', maxWidth: '70vh', borderRadius: '0.45vh', boxShadow: '0px 0.4vh 0.4vh rgba(0, 0, 0, 0.25'}}/>
    <InputLabelSmall>
      {<span style={{fontSize: '2vh', color: '#ffffff'}}>This calendar does not account for unexpected closings. Check our main page or socials for up to date info!</span>}
  </InputLabelSmall>
    </SpotlightContainer>
  );
}
export default CalendarComponent;
